import { remove } from 'lodash'


import TrainingSession from '@state/models/training-session'

export const state = {
  trainingSessionId: null,

  draftInviteEmails: [],
  draftInviteUsers: [],

  draftScormFileObjects: [],
  errorInScormFiles: false,
  errorInParticipants: false,

  draftStartDate: null,
  draftInterval: null,
  draftIntervalUnit: null,
  draftReminderQuantity: null,
  draftReminderUnit: null,
  draftDescription: null,

  draftIsRelativeInterval: false,
  draftisRelativeReminder: false,

  draftReminderDates: [],

}

export const mutations = {
  SET_TRAINING_SESSION_ID(state, trainingSessionId) {
    state.trainingSessionId = trainingSessionId
  },
  SET_DRAFT_INVITE_EMAILS(state, emailObject) {
    state.draftInviteEmails.push(emailObject)
  },
  SET_DRAFT_INVITE_USERS(state, userObject) {
    state.draftInviteUsers.push(userObject)
  },
  SET_DRAFT_SCORM_FILE_OBJECTS(state, draftScormFileObjects) {
    state.draftScormFileObjects.push(...draftScormFileObjects)
  },
  SET_DRAFT_SCORM_FILE_OBJECT(state, scormFileObject) {
    state.draftScormFileObjects.push(scormFileObject)
  },
  REMOVE_DRAFT_SCORM_FILE_ID(state, scormFileId) {
    remove(state.draftScormFileObjects, (scormFile) => scormFile.id === scormFileId)
    const hasErrorScormFiles = state.draftScormFileObjects.some((scormFile) => scormFile.error)
    if (!hasErrorScormFiles) {
      state.errorInScormFiles = false
    }
  },
  REMOVE_DRAFT_INVITE_EMAIL(state, draftObject) {
    state.draftInviteEmails = state.draftInviteEmails.filter((e) => e.email !== draftObject.email)
    const hasErrorInvites = state.draftInviteEmails.some((e) => e.error)
    if (!hasErrorInvites) {
      state.errorInParticipants = false
    }
  },
  REMOVE_DRAFT_INVITE_USER(state, draftObject) {
    state.draftInviteUsers = state.draftInviteUsers.filter((u) => u.id !== draftObject.id)
    const hasErrorInvites = state.draftInviteUsers.some((u) => u.error)
    if (!hasErrorInvites) {
      state.errorInParticipants = false
    }
  },
  RESET_DRAFT(state) {
    state.draftInviteEmails = []
    state.draftInviteUsers = []
    state.draftScormFileObjects = []
    state.draftBulkInviteUploadFile = null
    state.errorInScormFiles = false
    state.errorInParticipants = false
    state.draftReminderQuantity = null
    state.draftReminderUnit = null
    state.draftInterval = null
    state.draftIntervalUnit = null
    state.draftStartDate = null
    state.draftDescription = null
    state.draftReminderDates = []
  },
  RESET_SCORM_FILES_DRAFT_WITHOUT_ERRORS(state, errors) {

    state.draftScormFileObjects = state.draftScormFileObjects.filter((scormFile) => scormFile.error)
    state.draftBulkInviteUploadFile = null
  },
  RESET_USER_DRAFT_WITHOUT_ERRORS(state, errors) {
    state.draftInviteUsers = state.draftInviteUsers.filter((user) => user.error)
    state.draftInviteEmails = state.draftInviteEmails.filter((email) => email.error)
  },
  REMOVE_DRAFT_SCORM_FILES(state) {
    state.draftScormFileObjects = []
  },
  REMOVE_DRAFT_PARTICIPANTS(state) {
    state.draftInviteEmails = []
    state.draftInviteUsers = []
  },
  SET_ERROR_IN_SCORM_FILES(state, error) {
    state.errorInScormFiles = error
  },
  SET_ERROR_IN_PARTICIPANTS(state, error) {
    state.errorInParticipants = error
  },
  SET_DRAFT_START_DATE(state, startDate) {
    state.draftStartDate = startDate
  },
  REMOVE_DRAFT_START_DATE(state) {
    state.draftStartDate = null
  },

  SET_DRAFT_INTERVAL(state, interval) {
    state.draftInterval = interval
  },
  REMOVE_DRAFT_INTERVAL(state) {
    state.draftInterval = null
  },

  SET_DRAFT_INTERVAL_UNIT(state, intervalUnit) {
    state.draftIntervalUnit = intervalUnit
  },
  REMOVE_DRAFT_INTERVAL_UNIT(state) {
    state.draftIntervalUnit = null
  },

  SET_DRAFT_REMINDER_QUANTITY(state, reminderQuantity) {
    state.draftReminderQuantity = reminderQuantity
  },
  REMOVE_DRAFT_REMINDER_QUANTITY(state) {
    state.draftReminderQuantity = null
  },

  SET_DRAFT_REMINDER_UNIT(state, reminderUnit) {
    state.draftReminderUnit = reminderUnit
  },
  REMOVE_DRAFT_REMINDER_UNIT(state) {
    state.draftReminderUnit = null
  },
  SET_DRAFT_REMINDER_DATES(state, reminderDate) {
    state.draftReminderDates.push(reminderDate)
  },
  SET_DRAFT_DESCRIPTION(state, description) {
    state.draftDescription = description
  },
  RESET_DRAFT_SETTINGS(state, getters) {
    state.draftStartDate = null
    state.draftInterval = null
    state.draftIntervalUnit = null
    state.draftReminderQuantity = null
    state.draftReminderUnit = null
    state.draftReminderDates = []
    state.draftDescription = null
    state.draftReminderDates = []
  },
  REMOVE_DRAFT_REMINDER_DATE(state, reminderDate) {
    state.draftReminderDates = state.draftReminderDates.filter(
      (date) => date.start_timestamp !== reminderDate
    )
  },
  SET_IS_RELATIVE_INTERVAL(state, draftIsRelativeInterval) {
    state.draftIsRelativeInterval = draftIsRelativeInterval
  },
  SET_IS_RELATIVE_REMINDER(state, draftIsRelativeReminder) {
    state.draftIsRelativeReminder = draftIsRelativeReminder
  }
}

export const actions = {
  setTrainingSessionId({ commit }, trainingSessionId) {
    commit('SET_TRAINING_SESSION_ID', trainingSessionId)
  },
  setDraftInviteEmail({ commit, state }, email) {
    const normalizedEmail = email.toLowerCase()
    const exists = state.draftInviteEmails.some(e => e.email.toLowerCase() === normalizedEmail)
    if (!exists) {
      const emailObject = {
        email: normalizedEmail,
        added: true,
      }
      commit('SET_DRAFT_INVITE_EMAILS', emailObject)
    }
  },
  setDraftInviteUser({ commit, state }, user) {
    const normalizedEmail = user.email.toLowerCase()

    const exists = state.draftInviteUsers.some(
      u => u.id === user.id || u.email.toLowerCase() === normalizedEmail
    )
    if (!exists) {
      const userObject = {
        id: user.id,
        email: normalizedEmail,
        added: true,
      }
      commit('SET_DRAFT_INVITE_USERS', userObject)
    }
  },
  removeDraftUserOrEmail({ commit }, draftObject) {
    if (draftObject.id) {
      commit('REMOVE_DRAFT_INVITE_USER', draftObject)
    } else {
      commit('REMOVE_DRAFT_INVITE_EMAIL', draftObject)
    }
  },
  removeDraftScormFiles({ commit }) {
    commit('REMOVE_DRAFT_SCORM_FILES')
  },
  addDraftScormFileObject({ commit }, selectedScormFileId) {
    const mappedScormFileId = {
      id: selectedScormFileId,
      added: true,
    }
    commit('SET_DRAFT_SCORM_FILE_OBJECT', mappedScormFileId)
  },
  removeDraftScormFileId({ commit }, scormFileId) {
    const scormFileObject = {
      id: scormFileId,
      removed: true,
    }
    commit('SET_DRAFT_SCORM_FILE_OBJECT', scormFileObject)
  },
  removeAddedDraftScormFileId({ commit }, scormFileId) {
    commit('REMOVE_DRAFT_SCORM_FILE_ID', scormFileId)
  },
  resetDraft({ commit }) {
    commit('RESET_DRAFT')
  },
  resetDraftPartial({ commit }, type) {
    if (type === 'scormFiles') {
      commit('REMOVE_DRAFT_SCORM_FILES')
    } else if (type === 'participants') {
      commit('REMOVE_DRAFT_PARTICIPANTS')
    } else if (type === 'settings') {
      commit('RESET_DRAFT_SETTINGS')
    }
  },
  resetDraftAndAddErrorScormFiles({ commit }, errors) {
    commit('RESET_SCORM_FILES_DRAFT_WITHOUT_ERRORS', errors)
    const scormFileIds = errors.map((error) => error.scorm_file_id)

    for (let scormFileId of scormFileIds) {
      const message = errors
        .filter((error) => error.scorm_file_id === scormFileId)
        .map((error) => error.message)
      const scormFileObject = {
        id: scormFileId,
        message: message[0],
        error: true,
      }
      commit('SET_DRAFT_SCORM_FILE_OBJECT', scormFileObject)
    }
  },
  resetDraftAndAddErrorParticipants({ commit }, errors) {
    // First, reset the draft while preserving errors
    commit('RESET_USER_DRAFT_WITHOUT_ERRORS', errors)

    // Separate errors into invites and users based on the presence of 'user_id'
    const inviteErrors = errors.filter((error) => !error.user_id)
    const userErrors = errors.filter((error) => error.user_id)

    // Process invite errors
    for (let error of inviteErrors) {
      const participantObject = {
        email: error.email,
        message: error.message,
        error: true,
      }
      commit('SET_DRAFT_INVITE_EMAILS', participantObject)
    }

    // Process user errors
    for (let error of userErrors) {
      const participantObject = {
        id: error.user_id,
        email: error.email,
        message: error.message,
        error: true,
      }
      commit('SET_DRAFT_INVITE_USERS', participantObject)
    }
  },
  setDraftRemoveUser({ commit }, user) {
    const userObject = {
      id: user.id,
      email: user.email,
      removed: true,
    }
    commit('SET_DRAFT_INVITE_USERS', userObject)
  },
  setErrorInScormFiles({ commit }, error) {
    commit('SET_ERROR_IN_SCORM_FILES', error)
  },
  setErrorInParticipants({ commit }, error) {
    commit('SET_ERROR_IN_PARTICIPANTS', error)
  },

  setDraftStartDate({ commit }, startDate) {
    const startDateObject = {
      date: startDate,
      draft: true,
    }
    commit('SET_DRAFT_START_DATE', startDateObject)
  },
  removeDraftStartDate({ commit }) {
    commit('REMOVE_DRAFT_START_DATE')
  },

  setDraftInterval({ commit }, interval) {
    commit('SET_DRAFT_INTERVAL', interval)
  },
  removeDraftInterval({ commit }) {
    commit('REMOVE_DRAFT_INTERVAL')
  },

  setDraftIntervalUnit({ commit }, intervalUnit) {
    commit('SET_DRAFT_INTERVAL_UNIT', intervalUnit)
  },
  removeDraftIntervalUnit({ commit }) {
    commit('REMOVE_DRAFT_INTERVAL_UNIT')
  },

  setDraftReminderQuantity({ commit }, reminderQuantity) {
    commit('SET_DRAFT_REMINDER_QUANTITY', reminderQuantity)
  },
  removeDraftReminderQuantity({ commit }) {
    commit('REMOVE_DRAFT_REMINDER_QUANTITY')
  },

  setDraftReminderUnit({ commit }, reminderUnit) {
    commit('SET_DRAFT_REMINDER_UNIT', reminderUnit)
  },
  removeDraftReminderUnit({ commit }) {
    commit('REMOVE_DRAFT_REMINDER_UNIT')
  },
  setDraftReminderDates({ commit }, reminderDate) {
    const reminderDateObject = {
      start_timestamp: reminderDate,
      last_triggered: null,
      added: true,
    }

    commit('SET_DRAFT_REMINDER_DATES', reminderDateObject)
  },
  setDraftRemoveReminderDate({ commit }, reminderDate) {
    const reminderDateObject = {
      start_timestamp: reminderDate.start_timestamp,
      last_triggered: null,
      removed: true,
      timer: reminderDate,
    }
    commit('SET_DRAFT_REMINDER_DATES', reminderDateObject)
  },
  setDraftDescription({ commit }, description) {
    commit('SET_DRAFT_DESCRIPTION', description)
  },
  removeDraftReminderDate({ commit }, reminderDate) {
    commit('REMOVE_DRAFT_REMINDER_DATE', reminderDate)
  },
  setDraftIsRelativeInterval({ commit }, draftIsRelativeInterval) {
    commit('SET_IS_RELATIVE_INTERVAL', draftIsRelativeInterval)
  },
  setDraftIsRelativeReminder({ commit }, draftIsRelativeReminder) {
    commit('SET_IS_RELATIVE_REMINDER', draftIsRelativeReminder)
  }
}

export const getters = {
  draftScormFileObjects(state) {
    return state.draftScormFileObjects
  },
  draftInviteEmails(state) {
    return state.draftInviteEmails
  },
  draftInviteUsers(state) {
    return state.draftInviteUsers
  },

  trainingSession(state) {
    return TrainingSession.query()
      .where((t) => t.id === state.trainingSessionId)
      .with('session_scorm_links')
      .with('reminders')
      .with('reminders.timers')
      .with('reminders.timers.timer_company_role_associations')
      .with('session_scorm_links.scorm_file')
      .with('session_scorm_links.company_role_scorm_status')
      .with('training_session_company_role_associations')
      .with('training_session_company_role_associations.company_role')
      .with('training_session_company_role_associations.company_role.user')
      .with('training_session_company_role_associations.company_role.invite')
      .first()
  },
  participants(state, getters) {
    if (!getters.trainingSession) {
      return []
    }

    return getters.trainingSession.training_session_company_role_associations.map((ts) => {
      return ts.company_role
    })
  },
  errorInScormFiles(state) {
    return state.errorInScormFiles
  },
  errorInParticipants(state) {
    return state.errorInParticipants
  },

  draftStartDate(state) {
    return state.draftStartDate
  },
  draftInterval(state) {
    return state.draftInterval
  },
  draftIntervalUnit(state) {
    return state.draftIntervalUnit
  },
  draftReminderQuantity(state) {
    return state.draftReminderQuantity
  },
  draftReminderUnit(state) {
    return state.draftReminderUnit
  },
  draftReminderDates(state) {
    return state.draftReminderDates
  },
  draftDescription(state) {
    return state.draftDescription
  },
  draftIsRelativeInterval(state) {
    return state.draftIsRelativeInterval
  },
  draftIsRelativeReminder(state) {
    return state.draftIsRelativeReminder
  }
}
