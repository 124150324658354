import { PermissionRoleType } from '@enums'

import Company from '@state/models/company'
import CompanyPerson from '@state/models/company-person'
import CompanyRoleScormStatus from '@state/models/company-role-scorm-status'
import Invite from '@state/models/invite'
import PermissionRole from '@state/models/permission-role'
import PermissionRoleCompanyAssociation from '@state/models/permission-role-company-association'
import PermissionRoleCompanyRoleAssociation from '@state/models/permission-role-company-role-association'
import PlanitModel from '@state/models/planit-model'
import Task from '@state/models/task'
import User from '@state/models/user'

import store from '@state/store'
import TrainingSession from './training-session'

import File from '@state/models/file'
import TimerCompanyRoleAssociation from '@state/models/timer-company-role-association'
import axios from 'axios'

export default class CompanyRole extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      company: this.belongsTo(Company, 'company_id'),
      company_id: this.attr(),

      user: this.belongsTo(User, 'user_id'),
      user_id: this.attr(),

      invite: this.belongsTo(Invite, 'invite_id'),
      invite_id: this.attr(),

      // permissions: this.hasMany(Permission, 'company_role_id'),
      company_role_scorm_status: this.hasMany(CompanyRoleScormStatus, 'company_role_id'),
      company_persons: this.hasMany(CompanyPerson, 'company_role_id'),

      permission_role_company_role_associations: this.hasMany(
        PermissionRoleCompanyRoleAssociation,
        'company_role_id'
      ),

      title: this.attr(),

      permission_role_types: this.attr(),

      is_light_account: this.attr(),
      is_company_admin: this.attr(),
      is_documents_admin: this.attr(),
      can_edit_documents: this.attr(),
      is_training_admin: this.attr(),

      timer_company_role_association: this.hasMany(TimerCompanyRoleAssociation, 'company_role_id'),

      // local attributes
      editMode: this.attr(),
    }
  }

  async sync(attr, data, config, callback) {
    data = data || this.getSyncData(attr)

    /* console.log('deleting permissions')
    await Permission.delete(p => p.company_role_id === this.id) */

    if (callback) {
      return this.postOrPatch(data, config)
        .then((r) => {
          return this.syncQueueReplace(r)
        })
        .then(callback)
    } else {
      return this.postOrPatch(data, config).then((r) => {
        return this.syncQueueReplace(r)
      })
    }
  }

  getPermissionRoleCompanyRoleAssociation(permissionRoleType) {
    const permissionRole = PermissionRole.allFast().find((pr) => {
      return (
        pr.permission_role_type === permissionRoleType &&
        !!PermissionRoleCompanyAssociation.allFast().find(
          (prca) => prca.permission_role_id === pr.id && prca.company_id === this.company_id
        )
      )
    })

    // e.g. no access permission-role exists
    if (!permissionRole) {
      return null
    }

    const prcra = PermissionRoleCompanyRoleAssociation.query()
      .where(
        (_prcra) =>
          _prcra.permission_role_id === permissionRole.id && _prcra.company_role_id === this.id
      )
      .with('permission_role')
      .with('permission_role.permission_role_model_associations')
      .first()

    return prcra
  }

  getAccessPermissionRoleCompanyRoleAssociations(permissionRoleType) {
    return PermissionRoleCompanyRoleAssociation.query()
      .where(
        (prcra) =>
          prcra.company_role_id === this.id &&
          !!PermissionRole.allFast().find(
            (pr) =>
              pr.id === prcra.permission_role_id && pr.permission_role_type === permissionRoleType
          )
      )
      .with('permission_role')
      .all()
  }

  async refreshCompanyRoleScormStatus(
    load_current_sessions = false,
    load_all_completed_without_training_sessions = false
  ) {
    await CompanyRoleScormStatus.deleteAll()

    return this.refreshSubResource(CompanyRoleScormStatus, {
      params: {
        load_current_sessions,
        load_all_completed_without_training_sessions,
      },
    })
  }

  getScormStatusByScormFile(scormFile, loadExpired = false) {
    const crss = CompanyRoleScormStatus.allFast().find((crss) => {
      let matches = crss.scorm_file_id === scormFile.id && crss.company_role_id === this.id

      if (loadExpired === false) {
        matches = matches && crss.is_expired === false
      }

      return matches
    })
    return crss ? CompanyRoleScormStatus.find(crss.id) : null
  }

  createCompanyRoleScormStatus(data) {
    return CompanyRoleScormStatus.api().post(this.url() + CompanyRoleScormStatus.$url(), data)
  }

  getCompanyRoleScormStatus(start_date, end_date, loadFinishedCourses = false) {
    return CompanyRoleScormStatus.api().get(this.url() + CompanyRoleScormStatus.$url(), {
      params: {
        start_date,
        end_date,
        loadFinishedCourses,
      },
    })
  }

  delete() {
    const thisId = this.id
    return super.delete().then(() => {
      CompanyRoleScormStatus.delete((crss) => crss.company_role_id === thisId)
      CompanyPerson.delete((cp) => cp.company_role_id === thisId)
    })
  }

  getPrimaryCompanyRoute() {
    if (this.company && this.company.demo_import_loading) {
      return {}
    }

    if (
      store.getters['auth/isAdmin'] ||
      this.permission_role_types.indexOf(PermissionRoleType.COMPANY_ADMIN) !== -1
    ) {
      return { name: 'company.dashboard', params: { companyId: this.company_id } }
    } else if (this.permission_role_types.indexOf(PermissionRoleType.DOCUMENTS) !== -1) {
      return store.getters['navbar/appSelectItems'].find(
        (asi) => asi.value === 'dataProtectionDocumentation'
      ).to
    } else if (this.permission_role_types.indexOf(PermissionRoleType.SCORM_FILES) !== -1) {
      return store.getters['navbar/appSelectItems'].find((asi) => asi.value === 'training').to
    }

    return {
      name: 'profile.dashboard',
    }
  }

  async loadMyTasks(params) {
    return await Task.api().get(this.url() + '/my-tasks', { params })
  }
  async addToTrainingSessions(trainingSessionIds) {
    return await TrainingSession.api().post(this.url() + '/training-sessions', {
      training_session_ids: trainingSessionIds,
    })
  }
  async downloadAllScormFileCertificates(crssIds) {
    const zip = crssIds.length > 1

    const getFilenameFromDisposition = (disposition, defaultName) => {
      let filename = defaultName
      if (disposition && disposition.includes('attachment')) {
        const parts = disposition.split('filename=')
        if (parts.length > 1) {
          filename = parts[1].trim().replace(/['"]/g, '') // Remove any surrounding quotes
        }
      }
      return filename
    }
    const getFileExtension = (contentType) => {
      const mimeTypes = {
        'application/pdf': 'pdf',
      }
      return mimeTypes[contentType] || 'pdf'
    }

    if (zip) {
      return await File.api().post(this.url() + '/bulk-company-role-scorm-status-certificates', {
        crss_ids: crssIds,
      })
    } else {
      const certificateUrls = crssIds.map(
        (id) => `/api/company-role-scorm-status/${id}/certificate`
      )
      const promises = certificateUrls.map((url) =>
        axios.get(url, {
          responseType: 'blob',
        })
      )
      const responses = await Promise.all(promises)

      responses.forEach((response, index) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        const contentType = response.headers['content-type']
        const extension = getFileExtension(contentType)
        const defaultFilename = `certificate_${crssIds[index]}.${extension}`
        const filename = getFilenameFromDisposition(
          response.headers['content-disposition'],
          defaultFilename
        )
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
      return null
    }
  }
  getCurrentCompanyRoleScormStatus(scorm_file_id) {
    return CompanyRoleScormStatus.api()
      .get(this.url() + '/current-company-role-scorm-status?scorm_file_id=' + scorm_file_id)
      .then((result) => {
        return result
      })
  }

  static getLostCompanyRoles() {
    return CompanyRole.api().get('/company-roles/lost')
  }
}

CompanyRole.entity = 'company-roles'
